import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import { Routes } from './routing/Routes'

type Props = {
  basename: string
}

const root = document.documentElement;
root?.style.setProperty("--background-color", "#FAFAFA");
root?.style.setProperty("--primary-text-color", "#8E9AA0");
root?.style.setProperty("--landing-text-color", "#1D252D");
root?.style.setProperty("--all-courses-title-color", "#B5BEC3");
root?.style.setProperty("--all-courses-section-title-color", "#FFFFFF");
root?.style.setProperty("--all-courses-section-title-background-color", "#4F008C");
root?.style.setProperty("--all-courses-section-title-triangle-color", "#F4B249");
root?.style.setProperty("--all-courses-grid-item-background-color", "#FFFFFF ");
root?.style.setProperty("--all-courses-info-color", "#8E9AA0");
root?.style.setProperty("--all-courses-item-subtitle-color", "#FFFFFF");
root?.style.setProperty("--all-courses-item-title-color", "#1D252D");
root?.style.setProperty("--all-courses-item-button-background-color", "#8E9AA0");
root?.style.setProperty("--all-courses-item-button-primary-background-color", "#03BB86");
root?.style.setProperty("--all-courses-item-button-color", "#B5BEC3");
root?.style.setProperty("--all-courses-item-button-primary-color", "#FFFFFF");
root?.style.setProperty("--footer-background-color", "#1D252D");
root?.style.setProperty("--footer-color", "#B5BEC3");
root?.style.setProperty("--scrollTop-background-color", "#394755");
root?.style.setProperty("--scrollTop-color", "#B5BEC3");
root?.style.setProperty("--selected-menu-item-border-color", "#00C48C");
root?.style.setProperty("--selected-navbar-item-border-color", "#F4B249");
root?.style.setProperty("--header-menu-item-color", "#8E9AA0");
root?.style.setProperty("--header-selected-menu-item-color", "#1D252D");
root?.style.setProperty("--myCourse-subjects-background-color", "#394755");
root?.style.setProperty("--myCourse-subjects-items-background-color", "#4C5B69");
root?.style.setProperty("--myCourse-subjects-color", "#FFFFFF");
root?.style.setProperty("--primary-button-background-color", "#03BB86");
root?.style.setProperty("--navbar-myCourses-background-color", "#4F008C");
root?.style.setProperty("--navbar-myCourses-color", "rgba(255,255,255,0.65");
root?.style.setProperty("--navbar-myCourses-selected-color", "rgb(255,255,255");
root?.style.setProperty("--login-box-color", "#FFFFFF");
root?.style.setProperty("--login-welcome-message", "#B5BEC3");
root?.style.setProperty("--navbar-myCourses-color", "rgba(255,255,255,0.65)");
root?.style.setProperty("--navbar-myCourses-selected-color", "rgb(255,255,255)");
root?.style.setProperty("--faq-background-color", "#ffffff");
root?.style.setProperty("--faq-button-color", "#00C48C");
root?.style.setProperty("--faq-text-color", "#F5F5F5");
root?.style.setProperty("--trainer-container-background-color", "#4C5B69");
root?.style.setProperty("--trainer-container-color", "#FFFFFF");
root?.style.setProperty("--add-discussion-button-background-color", "#4C5B69");
root?.style.setProperty("--add-discussion-button-color", "#B5BEC3");
root?.style.setProperty("--certificate-button-background-color", "#FFFFFF");
root?.style.setProperty("--certificate-button-color", "#8E9AA0");
root?.style.setProperty("--no-course-text-color", "#B5BEC3");
root?.style.setProperty("--searchBox-inputs", "#FFFFFF");
root?.style.setProperty("--Icon_feather-play", "#F4B249");
root?.style.setProperty("--errorpage-icon-color", "#4F008C");
root?.style.setProperty("--errorpage-title-color", "#1D252D");
root?.style.setProperty("--errorpage-text-color", "#8E9AA0");
root?.style.setProperty("--errorpage-btn-bg-color", "#03BB86");
root?.style.setProperty("--errorpage-btn-text-color", "#F5F5F5");
root?.style.setProperty("--myCourse-subjects-lang-toggle-background-color", "#03BB86");
root?.style.setProperty("--myCourse-subjects-lang-toggle-text-color", "#F5F5F5");
root?.style.setProperty("--myCourse-quizResult-bg-color", "#E5EBF0");
root?.style.setProperty("--myCourse-quizResult-success-color", "#2A7E4F");






const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
