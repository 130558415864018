/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { post } from '../networking/requestService'

type Props = {
  point: number
  campaign: any
  sectionClick: (input: any) => void
}

const FinishedCourse: FC<Props> = (props: Props) => {
  const intl = useIntl()

  const requiredToPass = 75

  const [loading, setLoading] = useState(false)

  const getCertificate = () => {
    setLoading(true)

    const lmsData = JSON.parse(props.campaign.lmsUnits)
    const queryParams = {
      fn: 'getCertificateDownloadToken',
      campaignId: props.campaign.id,
      issueDate: lmsData.certificateDate,
    }
    console.log(lmsData.certificateDate, ": date");

    post(queryParams)
      .then(({ data: { token } }) => {
        const link =
          window.location.protocol + '//' + window.location.host + '/api/1/download?token=' + token
        setLoading(false)
        window.open(link, '_blank')
      })

  }


  return (
    <div className='quizResultsContainer row mx-0 justify-content-center'>
      <div className='col-3' style={{ maxWidth: '209px', maxHeight: '170px' }}>
        <img
          width='100%'
          height='auto'
          src={
            props.point >= requiredToPass
              ? toAbsoluteUrl('/media/misc/quiz_success.png')
              : toAbsoluteUrl('/media/misc/quiz_failure.png')
          }
          alt='quiz-result'
        />
      </div>
      <div className='col-9 col-md-9 col-lg-6 col-xl-5 mx-md-12 mx-xl-20'>
        <p className='quizResultsTitle mb-12'>
          {intl.formatMessage({
            id: `${props.point >= requiredToPass ? 'POPLEADS.CONGRATULATIONS' : 'POPLEADS.YOU_FAILED'
              }`,
          })}
        </p>
        <div className='quizResultsSubTitle'>
          {intl.formatMessage(
            {
              id: 'POPLEADS.QUIZ_SCORE',
            })}

          <div
            className={`${props.point >= requiredToPass
              ? 'quizResultsSuccessText'
              : 'quizResultsFailureText'
              } mt-2 mb-6`}
          >
            {props.point}
          </div>

        </div>

        {props.point >= requiredToPass ? (
          <div className='quizResultsSubText mb-12'>
            {intl.formatMessage({ id: 'POPLEADS.QUIZ_SUCCESS' })}
          </div>
        ) : (
          <div className='quizResultsSubText mb-12'>
            {intl.formatMessage(
              { id: 'POPLEADS.QUIZ_FAILURE' },
              { html: (value) => <b>{value}</b>, passPoint: requiredToPass }
            )}
          </div>
        )}
        <div className='mb-6'>
          {props.point >= requiredToPass ? (
            <button
              className='quizResultsButton success'
              disabled={loading}
              onClick={() => getCertificate()}
            >
              <span>{intl.formatMessage({ id: 'POPLEADS.GET_CERTIFICATE' })}</span>
            </button>
          ) : (
            <button
              className='quizResultsButton'
              onClick={() => props.sectionClick('take-quiz-again')}
            >
              <span>{intl.formatMessage({ id: 'POPLEADS.TAKE_AGAIN' })}</span>
            </button>
          )}
          <button
            className='quizResultsButton secondary'
            onClick={() => props.sectionClick('play-again')}
          >
            <KTSVG path='/media/svg/popleads/playCircle.svg' />
            <span>{intl.formatMessage({ id: 'POPLEADS.PLAY_AGAIN' })}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export { FinishedCourse }
