/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { Landing } from "../partials/landing"
import { AllCourses } from "../partials/allCourses"
import { post } from '../networking/requestService';
import { Loading } from '../partials/loading';
import { Error } from '../partials/error';



const Home: FC = () => {

    const [courses, setCourses] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [sorting, setSorting] = useState("basTarih");
    const [orderType, setOrderType] = useState("asc");
    const changeSortingAndOrder = (sorting:any,orderType:any) => {
        setSorting(sorting);
        setOrderType(orderType);
    }

    useEffect(() => {
        const requestObj = {
            fn: 'getProfileCampaigns',
          }
          post(requestObj).then(({data: {campaignCurrentList, campaignHistoryList}}) => {
            setCourses({
                campaignList: [
                  ...campaignCurrentList,
                  ...campaignHistoryList
                ]
              });
              setLoading(false);
          }).catch((err) => {
            setError({ text: err.response.data.text, code: err.response.status });
            setLoading(false);
          });

    }, [sorting,orderType]);
    return (
        <>
            <Landing />
            {
                loading ? <Loading />
                    :
                    error ? <Error text={error.text} code={error.code} />
                        : <AllCourses campaignList = {courses.campaignList} sortAndOrder = {(sort:any,order:any) => changeSortingAndOrder(sort,order)}/>
            }
        </>
    )
}

export { Home }
